/* eslint-disable @typescript-eslint/camelcase */
import { ShipmentBookingEntities } from "@/domain/entities/ShipmentBooking";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { ShipmentBookingPresenter } from "@/app/ui/presenters/ShipmentBookingPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { RequestListOnProcessStt } from "@/data/payload/api/OnProcessApiRequest";

export interface OnProcessSttState {
  isLoading: boolean;
  onProcessSttData: ShipmentBookingEntities;
  isError: boolean;
  isErrorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "on_process_stt"
})
class OnProcessSttStore extends VuexModule implements OnProcessSttState {
  public isLoading = false;
  public isLoadingDetail = false;
  public onProcessSttData = new ShipmentBookingEntities(
    new Pagination(1, 20),
    []
  );
  public search = "";
  public status = "";
  public filter = {
    originCode: "",
    destinationCode: "",
    clientPartner: "",
    product: [],
    COD: false,
    DFOD: false,
    asuransi: []
  };
  public isError = false;
  public isErrorCause = "";
  public firstRequest = true;
  public sttAssessmentStatus = "";

  @Action
  public getListOnProcessStt(params: RequestListOnProcessStt) {
    this.setLoading(true);
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter
      .getOnProcessSttList(params)
      .then((res: ShipmentBookingEntities) => {
        this.setOnProcessSttData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public fetchOnProcessSttList() {
    this.setFirstRequest(false);
    this.getListOnProcessStt(
      new RequestListOnProcessStt({
        search: this.search,
        page: this.onProcessSttData.pagination.page,
        limit: this.onProcessSttData.pagination.limit,
        originDistrictId: this.filter.originCode,
        destinationDistrictId: this.filter.destinationCode,
        productTypes: this.filter.product.toString().toUpperCase(),
        statusType: this.status.toUpperCase(),
        isCod: this.filter.COD,
        isDfod: this.filter.DFOD,
        insuranceTypes: this.filter.asuransi.toString().toUpperCase(),
        version: 2,
        isTotalData: true,
        sttAssessmentStatus: this.sttAssessmentStatus
      })
    );
  }

  @Action
  public async initOnProcessSttList() {
    this.setLoading(true);
    setTimeout(() => {
      this.setOnProcessSttData(
        new ShipmentBookingEntities(new Pagination(1, 20), [])
      );
      const accountType =
        AccountController.accountData.account_type_detail.type;
      this.setFirstRequest(true);
      this.setSearch("");
      this.setStatus(
        accountType === "console"
          ? "all"
          : accountType === "sub-console"
          ? "sti-sc"
          : accountType === "pos"
          ? "pup"
          : ""
      );
      this.resetFilter();
      this.setSttAssessmentStatus("");
      this.getListOnProcessStt(
        new RequestListOnProcessStt({
          search: this.search,
          page: this.onProcessSttData.pagination.page,
          limit: this.onProcessSttData.pagination.limit,
          originDistrictId: this.filter.originCode,
          destinationDistrictId: this.filter.destinationCode,
          productTypes: this.filter.product.toString().toUpperCase(),
          statusType: this.status.toUpperCase(),
          isCod: this.filter.COD,
          isDfod: this.filter.DFOD,
          insuranceTypes: this.filter.asuransi.toString().toUpperCase(),
          version: 2,
          isTotalData: true
        })
      );
    }, 500);
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public setFilter(value: any) {
    this.filter = value;
  }

  @Mutation
  public setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setOnProcessSttData(value: ShipmentBookingEntities) {
    this.onProcessSttData = value;
  }

  @Mutation
  public resetPage() {
    this.onProcessSttData.pagination.page = 1;
  }

  @Action
  public setFirstPage() {
    this.onProcessSttData.pagination.page = 1;
  }

  @Action
  public resetFilter() {
    this.setFilter({
      originCode: "",
      destinationCode: "",
      clientPartner: "",
      product: [],
      COD: false,
      DFOD: false, 
      asuransi: []
    });
  }

  @Action
  public async selectSttAssessmentStatusAction(value: string) {
    await this.setSttAssessmentStatus(value);
    await this.resetPage();
    await this.fetchOnProcessSttList();
  }

  @Mutation
  private async setSttAssessmentStatus(value: string) {
    this.sttAssessmentStatus = value;
  }
}

export const OnProcessSttController = getModule(OnProcessSttStore);
